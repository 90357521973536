<template>
  <!-- モーダル本体 -->
  <common-modal
    :close-btn-flg="closeBtnFlg"
    header-text="お子様を選択"
    :hidden-footer-line="true"
    :is-original="true"
    modal-margin-x="px-3"
    modal-height="h-[60vh]"
    role="guard"
    @close="$emit('close')"
  >
    <!-- #Bodyテンプレート -->
    <template v-slot:body>
      <div
        class="
          mt-9
          px-3
        "
      >
        <common-profile-student
          v-for="child in childList"
          :key="child.userId"
          :child="child"
          :select-flg="selectedChild != undefined ? child.userId == selectedChild.userId : false"
          :setting-flg="false"
          @click="$emit('submit', child)"
          class="
            mb-4
          "
        />
      </div>
    </template>
  </common-modal>
</template>
<script setup lang="ts">
  import { ref } from 'vue'
  import type { Child } from '~~/types/child'

  const props = defineProps<{
    closeBtnFlg: boolean,
    childList: Child[],
    selectedChild: Child,
  }>()

  defineEmits<{
    (e: 'close'): void
    (e: 'submit', value: Child): void
  }>()

  const childList = props.childList
  const selectedChild = ref(props.selectedChild)
  const closeBtnFlg = props.closeBtnFlg !== undefined ? props.closeBtnFlg : true
</script>
